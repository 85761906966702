























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Links',
  setup() {
    const items = [
      {
        title: 'サイト更新　UNIT04',
        href: 'https://fave.unit04.io/#/login',
      },
      {
        title: 'メール配信　SendGrid',
        href: 'https://app.sendgrid.com/login',
      },
      {
        title: '動画配信　Vimeo',
        href: 'https://vimeo.com/jp/',
      },
    ];

    if (process.env.VUE_APP_DATA_PORTAL_LINK) {
      items.push({
        title: '分析　データポータル',
        href: process.env.VUE_APP_DATA_PORTAL_LINK,
      });
    }

    return { items };
  },
});
